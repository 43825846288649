@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap');
@import './assets/fonts/index.css';

body {
  min-height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
}

.name-field {
  display: flex;
  align-items: flex-end;
}

.relative {
  position: relative;
}

.file-input,
.upload-image {
  position: absolute;
  width: 150px;
  height: 150px;
  top: -50px;
  right: 100px;
  object-fit: cover;
}

.photo-add {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -50px;
  right: 100px;
  width: 150px;
  height: 150px;
}

.upload-image {
  border: 1px solid black
}

.file-input:hover {
  opacity: 1;
  border-radius: 0;
}

.file-input::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  background-image: url('https://cdn4.iconfinder.com/data/icons/instagram-ui-twotone/48/Paul-12-256.png');
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: 10000000;
}

.file-input {
  position: absolute;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  opacity: 0;
  z-index: 1000;
  overflow: hidden;
  cursor: pointer;
  outline: none;
}

.modal {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-inner {
  height: 65vh;
  width: 50vw;
  background-color: white;
  z-index: 10000;
}

.modal-open {
  display: flex;
}

.editorOverlayOuter {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.cross-avatar-editor {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.overflow {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.cropCanvas {
  object-fit: cover;
  width: 40vw;
  height: 40vw;
}

.cropCnt {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.editor-buttons {
  display: flex;
  justify-content: center;
}

.trash-icon {
  position: absolute;
  top: -50px;
  right: 100px;
  z-index: 1000;
  opacity: 1;
  cursor: pointer;
}


.reqFieldForDate{
  width: 100%;
  margin-bottom: 16px;
}

.reqFieldForDate label{
  color: red!important;
}

.reqFieldForDate div::before{
  border-bottom: 1px solid red!important;
}