@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url('./montserrat/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Medium';
  src: local('Montserrat Medium'),
    url('./montserrat/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat SemiBold';
  src: local('Montserrat SemiBold'),
    url('./montserrat/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Bold';
  src: local('Montserrat Bold'),
    url('./montserrat/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'),
    url('./open-sans/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans Light';
  src: local('Open Sans Light'),
    url('./open-sans/OpenSans-Light.ttf') format('truetype');
}
